<template>
    <div>
        <header-portrate />

    <div class="m-2">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />
        
        <v-row class="m-2">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.counter }}</td>
                            <td>{{ row.item.group_name }}</td>
                            <td>{{ row.item.permission }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </div>
        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
export default{
  components: { breadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            active_tab:0,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
            return [
                {
                    text: '',
                    align: 'center',
                    filterable: false,
                    value: 'counter',
                },
                {
                    text: this.lang.group_name,
                    align: 'center',
                    filterable: false,
                    value: 'group_name',
                },
                {
                    text: this.lang.permissions,
                    align: 'center',
                    filterable: false,
                    value: 'permission',
                },
            ]
        },
        tablerows: function() {
            return [
                {
                    counter: 1,
                    group_name: this.lang.administratior,
                    permission: this.lang.full_permissions
                },
                {
                    counter: 2,
                    group_name: this.lang.accountant,
                    permission: this.lang.accountatn_permissions,
                },
                {
                    counter: 3,
                    group_name: this.lang.cashier,
                    permission: this.lang.cashier_permissions
                }
            ]
        },
            
        items: function () {
                return {
                    text: this.lang.permissions,
                    disabled: true,
                    to: '/settings/permissions-settings',
                }
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.system_settings,href:'/settings/system-settings', class: 'mytab1'
                },
                {
                    index:1,name:this.lang.users,href:'/settings/users-settings', class: 'mytab2'
                },
                {
                    index:2,name:this.lang.permission,href:'/settings/permissions-settings', class: 'mytab'
                }
            ]
        }
    },
}
</script>